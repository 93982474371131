import Vue from "vue";
import Vuex from "vuex"
import axios from "axios";
import globalData from '@/global.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 全国土壤
        chinaList: [],
        // 桑园土壤
        sangyuanList: [],
        // 四川
        sichuanList: [],
        // 土种
        class: -1,
        subClassList: [],
        subClass: -1,
        speciesList: []
    },
    mutations: {
        // 全国土壤
        chinaListEmptyMutation(state) {
            state.chinaList = [];
        },
        chinaListMutation(state, data) {
            state.chinaList.push(...data);
        },
        deletechinaListMutation(state, data) {
            state.chinaList = state.chinaList.filter(function (item) {
                return item.profile_id != data
            })
        },
        // 桑园土壤
        sangyuanListEmptyMutation(state) {
            state.sangyuanList = [];
        },
        sangyuanListMutation(state, data) {
            // console.log(state, data)
            state.sangyuanList.push(...data);
            // console.log(state.sangyuanList)
        },
        deleteSangyuanListMutation(state, data) {
            state.sangyuanList = state.sangyuanList.filter(function (item) {
                return item.id != data
            })
        },
        // 四川
        sichuanListEmptyMutation(state) {
            state.sichuanList = [];
        },
        sichuanListMutation(state, data) {
            state.sichuanList.push(...data);
        },
        deleteSichuanListMutation(state, data) {
            state.sichuanList = state.sichuanList.filter(function (item) {
                return item.id != data
            })
        },
        // 土种
        classMutation(state, data) {
            state.class = data
        },
        subClassListMutation(state, data) {
            state.subClassList = data.map(element => {
                return {
                    subgroup_gb_name: element.subgroup_gb_name,
                    subgroup_id: element.subgroup_id
                }
            });
        },
        subClassMutation(state, data) {
            state.subClass = data
        },
        speciesListMutation(state, data) {
            // console.log(data)
            state.speciesList = data
        }
    },
    actions: {
        // 全国土壤
        getChinaListAction(store) {
            store.commit('chinaListEmptyMutation');

            function makaPromiseList(dataArr, handler) {
                return dataArr.reduce((promise, obj) => {
                    return promise.then(() => {
                        return handler(obj);
                    })
                }, Promise.resolve())
            }

            function makeRequest(obj) {
                const url = `./data/getProfileBaseMessage?provinceId=${obj.provice_id}`
                return new Promise((resolve, reject) => {
                    axios
                        .get(url)
                        .then((res) => {
                            // console.log(res.data);
                            if (res.data.code != 200) {
                                this._vm.$message.error('操作失败, 请检查服务器');
                                reject();
                            }
                            store.commit('chinaListMutation', res.data.data)
                            resolve()
                        });
                })
            }

            makaPromiseList(globalData.provinceArr, makeRequest).then(() => {
                // this._vm.$message({
                //     message: "加载完成",
                //     type: "success",
                // });
            });

        },
        // 桑园土壤
        getSangyuanListAction(store) {
            store.commit('sangyuanListEmptyMutation');

            function makaPromiseList(dataArr, handler) {
                return dataArr.reduce((promise, item) => {
                    return promise.then(() => {
                        return handler(item);
                    })
                }, Promise.resolve())
            }

            function makeRequest(item) {
                const url = `./data_mulberry/getMulberryMessageByProvince?province=${item}`
                return new Promise((resolve, reject) => {
                    axios
                        .get(url)
                        .then((res) => {
                            // console.log(res.data);
                            if (res.data.code != 200) {
                                this._vm.$message.error('操作失败, 请检查服务器');
                                reject();
                            }
                            store.commit('sangyuanListMutation', res.data.data)
                            resolve()
                        });
                })
            }

            makaPromiseList(globalData.sangyuanProvinceArr, makeRequest).then(() => {
                // this._vm.$message({
                //     message: "加载完成",
                //     type: "success",
                // });
            });

        },
        // 四川
        getSichuanListAction(store) {
            store.commit('sichuanListEmptyMutation');

            function makaPromiseList(dataArr, handler) {
                return dataArr.reduce((promise, item) => {
                    return promise.then(() => {
                        return handler(item);
                    })
                }, Promise.resolve())
            }

            function makeRequest(item) {
                const url = `./data_tobacco/getSichuanTobaccoBasicDataByCityState?city_state=${item}`
                return new Promise((resolve, reject) => {
                    axios
                        .get(url)
                        .then((res) => {
                            // console.log(res.data);
                            if (res.data.code != 200) {
                                this._vm.$message.error('操作失败, 请检查服务器');
                                reject();
                            }
                            store.commit('sichuanListMutation', res.data.data)
                            resolve()
                        });
                })
            }

            makaPromiseList(globalData.sichuanLocationArr, makeRequest).then(() => {
                // this._vm.$message({
                //     message: "加载完成",
                //     type: "success",
                // });
            });

        },
        // 土种
        getSubClassListAction(store) {
            axios
                .get(
                    `./data_cultivate/getSubGroupBaseMessageByGroup_id?group_id=${store.state.class}`
                )
                .then((res) => {
                    // console.log(res.data);
                    store.commit('subClassListMutation', res.data.data)
                });
        },
        getSpeciesListAction(store) {
            // console.log(`./data_cultivate/getCultivateMessageBySubgroup_id?subgroup_id=${store.state.subClass}`)
            axios
                .get(
                    `./data_cultivate/getCultivateMessageBySubgroup_id?subgroup_id=${store.state.subClass}`
                )
                .then((res) => {
                    if (res.data.code != 200) {
                        this._vm.$message.error('操作失败, 请检查服务器');
                        return;
                    }
                    store.commit('speciesListMutation', res.data.data);
                    this._vm.$message({
                        message: "加载完成",
                        type: "success",
                    });
                });
        }
    }
})