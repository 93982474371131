<template>
  <div id="app">
    <navbar></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2">
          <ul class="aside">
            <li>
              <h4>四川植烟土壤管理</h4>
              <ul>
                <router-link
                  class="list-group-item router-link"
                  to="/sichuanlist"
                  activeClass="myactive"
                  tag="li"
                  >土壤数据列表</router-link
                >
                <router-link
                  class="list-group-item router-link"
                  to="/sichuanadd"
                  activeClass="myactive"
                  tag="li"
                  >新增土壤数据</router-link
                >
              </ul>
            </li>
            <li>
              <h4>全国土壤管理</h4>
              <ul>
                <router-link
                  class="list-group-item router-link"
                  to="/chinalist"
                  activeClass="myactive"
                  tag="li"
                  >土壤数据列表</router-link
                >
                <router-link
                  class="list-group-item router-link"
                  to="/chinaadd"
                  activeClass="myactive"
                  tag="li"
                  >新增土壤数据</router-link
                >
              </ul>
            </li>
            <li>
              <h4>全国桑园土壤管理</h4>
              <ul>
                <router-link
                  class="list-group-item router-link"
                  to="/sangyuanlist"
                  activeClass="myactive"
                  tag="li"
                  >土壤数据列表</router-link
                >
                <router-link
                  class="list-group-item router-link"
                  to="/sangyuanadd"
                  activeClass="myactive"
                  tag="li"
                  >新增土壤数据</router-link
                >
              </ul>
            </li>
            <li>
              <h4>土种管理</h4>
              <ul>
                <router-link
                  class="list-group-item router-link"
                  to="/soilspecieslist"
                  activeClass="myactive"
                  tag="li"
                  >土种列表</router-link
                >
                <router-link
                  class="list-group-item router-link"
                  to="/soilspeciesadd"
                  activeClass="myactive"
                  tag="li"
                  >新增土种</router-link
                >
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-sm-10">
          <router-view class="rightMain"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "@/components/Navbar";
// import "@/assets/admin/bootstrap/js/jquery-1.10.1.js";
import "@/assets/admin/bootstrap/css/bootstrap.css";
import "@/assets/admin/css/basic.css";

export default {
  components: {
    navbar,
  },
  mounted() {
    // document.body.style.zoom = 0.8;
  },
};
</script>

<style>
.rightMain {
  height: calc(100vh - 100px);
  width: 100%;
  overflow: auto;
}
.router-link {
  text-indent: 10px;
}
.myactive {
  text-decoration: underline;
}
.loading {
  height: 50px;
  line-height: 50px;
  text-align: center;
}
label {
  font-weight: normal;
  width: 120px;
  text-align: left;
  display: inline-block;
}
.input-form li {
  margin: 7px 0px;
}
</style>
