/* router */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter) // 注册Vue的路由插件

const routes = [
  {
    path: '/',
    component: () => import('@/views/Main')
  }, {
    path: '/sichuanlist',
    component: () => import('@/views/SichuanList')
  }, {
    path: '/sichuanadd',
    component: () => import('@/views/SichuanAdd')
  }, {
    path: '/sichuanedit',
    component: () => import('@/views/SichuanEdit')
  }, {
    path: '/chinalist',
    component: () => import('@/views/ChinaList')
  }, {
    path: '/chinaadd',
    component: () => import('@/views/ChinaAdd')
  }, {
    path: '/chinaedit',
    component: () => import('@/views/ChinaEdit')
  }, {
    path: '/sangyuanlist',
    component: () => import('@/views/SangyuanList')
  }, {
    path: '/sangyuanadd',
    component: () => import('@/views/SangyuanAdd')
  }, {
    path: '/sangyuanedit',
    component: () => import('@/views/SangyuanEdit')
  }, {
    path: '/soilspecieslist',
    component: () => import('@/views/SoilspeciesList')
  }, {
    path: '/soilspeciesadd',
    component: () => import('@/views/SoilspeciesAdd')
  }, {
    path: '/soilspeciesedit',
    component: () => import('@/views/SoilspeciesEdit')
  }
]

const router = new VueRouter({
  routes
})

export default router