<template>
  <nav class="navbar navbar-inverse" role="navigation">
    <div class="container-fluid">
      <!-- <div class="navbar-header"> -->

      <ul class="nav navbar-nav navbar-left">
        <li><img src="@/assets/土壤参数.png" /></li>
        <li><a class="title">土壤数据后台管理系统</a></li>
      </ul>
      <!-- </div> -->
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style>
.title {
  font-weight: bold;
  font-size: 18px;
}

img {
  height: 40px;
}

@media (min-width: 768px) {
  img {
    height: 50px;
  }
}
</style>