// 全国土壤 - 省份数组
const provinceArr = [
    {
        province: "北京",
        provice_id: 110000,
    },
    {
        province: "山西省",
        provice_id: 140000,
    },
    {
        province: "内蒙古自治区",
        provice_id: 150000,
    },
    {
        province: "辽宁省",
        provice_id: 210000,
    },
    {
        province: "吉林省",
        provice_id: 220000,
    },
    {
        province: "黑龙江省",
        provice_id: 230000,
    },
    {
        province: "江苏省",
        provice_id: 320000,
    },
    {
        province: "安徽省",
        provice_id: 340000,
    },
    {
        province: "江西省",
        provice_id: 360000,
    },
    {
        province: "山东省",
        provice_id: 370000,
    },
    {
        province: "河南省",
        provice_id: 410000,
    },
    {
        province: "湖南省",
        provice_id: 430000,
    },
    {
        province: "广东省",
        provice_id: 440000,
    },
    {
        province: "广西壮族自治区",
        provice_id: 450000,
    },
    {
        province: "四川省",
        provice_id: 510000,
    },
    {
        province: "贵州省",
        provice_id: 520000,
    },
    {
        province: "云南省",
        provice_id: 530000,
    },
    {
        province: "西藏自治区",
        provice_id: 540000,
    },
    {
        province: "甘肃省",
        provice_id: 620000,
    },
    {
        province: "新疆维吾尔自治区",
        provice_id: 650000,
    },
];
// 桑园土壤 - 省份数组
const sangyuanProvinceArr = ['四川省',
    '北京',
    '天津',
    '上海',
    '重庆',
    '河北省',
    '山西省',
    '辽宁省',
    '吉林省',
    '黑龙江省',
    '江苏省',
    '浙江省',
    '安徽省',
    '福建省',
    '江西省',
    '山东省',
    '河南省',
    '湖北省',
    '湖南省',
    '广东省',
    '海南省',
    '贵州省',
    '云南省',
    '陕西省',
    '甘肃省',
    '青海省',
    '台湾省',
    '西藏自治区',
    '新疆维吾尔自治区',
    '内蒙古自治区',
    '广西壮族自治区',
    '宁夏回族自治区',
    '香港',
    '澳门',]
const sichuanLocationArr = ['凉山州',
    '达州',
    '广元',
    '德阳',
    '泸州',
    '宜宾',
    '攀枝花',]
// 土类数组
const classArr = [{ group_id: 1, group_name: "赤红壤" },
{ group_id: 2, group_name: "红壤" },
{ group_id: 3, group_name: "黄壤" },
{ group_id: 4, group_name: "黄棕壤" },
{ group_id: 5, group_name: "黄褐土" },
{ group_id: 6, group_name: "棕壤" },
{ group_id: 7, group_name: "褐土" },
{ group_id: 8, group_name: "红粘土" },
{ group_id: 9, group_name: "新积土" },
{ group_id: 10, group_name: "石灰(岩)土" },
{ group_id: 11, group_name: "火山灰土" },
{ group_id: 12, group_name: "紫色土" },
{ group_id: 13, group_name: "石质土" },
{ group_id: 14, group_name: "粗骨土" },
{ group_id: 15, group_name: "砂姜黑土" },
{ group_id: 16, group_name: "山地草甸土" },
{ group_id: 17, group_name: "潮土" },
{ group_id: 18, group_name: "滨海盐土" },
{ group_id: 19, group_name: "酸性硫酸盐土" },
{ group_id: 20, group_name: "水稻土" },
{ group_id: 21, group_name: "暗棕壤" },
{ group_id: 22, group_name: "棕色针叶林土" },
{ group_id: 23, group_name: "白浆土" },
{ group_id: 24, group_name: "黑土" },
{ group_id: 25, group_name: "黑钙土" },
{ group_id: 26, group_name: "栗钙土" },
{ group_id: 27, group_name: "风沙土" },
{ group_id: 28, group_name: "草甸土" },
{ group_id: 29, group_name: "沼泽土" },
{ group_id: 30, group_name: "泥炭土" },
{ group_id: 32, group_name: "碱土" },
{ group_id: 33, group_name: "砖红壤" },
{ group_id: 34, group_name: "燥红土" },
{ group_id: 35, group_name: "磷质石灰土" },
{ group_id: 36, group_name: "灰色森林土" },
{ group_id: 37, group_name: "栗褐土" },
{ group_id: 38, group_name: "棕钙土" },
{ group_id: 39, group_name: "灰钙土" },
{ group_id: 40, group_name: "黄绵土" },
{ group_id: 41, group_name: "草甸盐土" },
{ group_id: 42, group_name: "灌淤土" },
{ group_id: 43, group_name: "草毡土" },
{ group_id: 44, group_name: "灰褐土" },
{ group_id: 45, group_name: "黑垆土" },
{ group_id: 46, group_name: "灰漠土" },
{ group_id: 47, group_name: "灰棕漠土" },
{ group_id: 48, group_name: "棕漠土" },
{ group_id: 49, group_name: "龟裂土" },
{ group_id: 50, group_name: "林灌草甸土" },
{ group_id: 51, group_name: "漠境盐土" },
{ group_id: 52, group_name: "灌漠土" },
{ group_id: 53, group_name: "黑毡土" },
{ group_id: 54, group_name: "寒钙土" },
{ group_id: 55, group_name: "寒钙土" },
{ group_id: 56, group_name: "寒漠土" },
{ group_id: 57, group_name: "灰化土" },
{ group_id: 58, group_name: "寒原盐土" },
{ group_id: 59, group_name: "冷棕钙土" },
{ group_id: 60, group_name: "冷漠土" },
{ group_id: 61, group_name: "寒冻土" },]

export default {
    provinceArr,
    sangyuanProvinceArr,
    classArr,
    sichuanLocationArr
}